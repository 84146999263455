import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { Helmet } from "react-helmet";

// Components
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardMedia, Container, Checkbox, Divider, Link, FormControlLabel, Grid, Paper, Stack, TextField, Typography, ToggleButton, ToggleButtonGroup, Tooltip, Input, useMediaQuery, useTheme, Fade, Skeleton, } from "@mui/material";
import { alpha } from '@mui/material/styles';
import { PasswordField, PasswordRequirements, FeedBack } from "../components/CustomStyledComponents";
import Header from "../layout/Header";
import CustomModals from "../layout/Modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


import { isNullOrEmpty, isEmail, checkPassword } from "../services/utilities";

// import Header
import { imageHero } from "../services/constants/dummyData";

// Icons
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// Styling
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Redux
import { purchaseContent, setToken, registerUser } from "../redux/slices/user-slice";
import { orderGiftCodes, payForwardOrder, setPromoCode, setReduxAlignment } from "../redux/slices/shop-slice";

// Data
import { getVideoById } from "../redux/slices/content-slice";

// Consts
import {  GIFT_CODE, BUY, RENT, CONTENT, ITF_TRAILER, SHOP_URL } from "../services/constants/keys";

const itl_thumbnail = 'https://itl-assets.b-cdn.net/thumbnails/1920x1080_grey_oswald.jpeg';
const jbs_banner = 'https://itl-assets.b-cdn.net/thumbnails/jb_logo.png';


const alphaBackgroundColor80 = alpha('#121212', .8);

const REGISTER = "register";
const OWN = 'own';
const CODES = 'codes';
const PAY_FORWARD = 'pay_forward';
const PRICE_LIST = {
  [RENT]: 4.99,
  [BUY]: 9.99
}

export default function PurchasePage() {
  const dispatch = useDispatch();
  const { is_verified } = useSelector((state) => state.user);
  const [settingsState, setSettingsState] = useState(false);
  const [loginState, setLoginState] = useState(false);

  const handleSettingsOpen = () => setSettingsState(true);
  const handleSettingsClose = () => setSettingsState(false);
  const handleLoginOpen =() => setLoginState(true);
  const handleLoginClose = () => setLoginState(false);

  const { promocode } = useParams();
  const reduxPromoCode = useSelector((state) => state.shop.promo_code);

  useEffect(() => {
    if(promocode && promocode !== "orders") {
      if (promocode !== reduxPromoCode) {
        dispatch(setPromoCode(promocode));
      }
    }
  },[promocode]);
  // initiats popup on landing
  // useEffect(() => {
  //   if(!is_verified) {
  //     setLoginState(true);
  //   } else {
  //     setLoginState(false);
  //   }
  // }, [is_verified, loginState])
  
  return (
    <>
    {/* <PaymentScript setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/> */}
    <Box sx={{ backgroundImage: `url(${imageHero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: "100%", position: "fixed", overflowY: "scroll" }}>
      {is_verified ? <Header type="videos" handleOpen={handleSettingsOpen} /> : <Header type="landing" handleOpen={handleLoginOpen} />}
      {settingsState && <CustomModals type="settings" handleClose={handleSettingsClose} open={settingsState} />}
      {loginState && <CustomModals type="login" handleClose={handleLoginClose} open={loginState} />}
      <Container sx={{mt: 10 }} maxWidth="md" >
        <Stack spacing={3}>
          { promocode === "jbs" && <CardMedia component="img" image={jbs_banner} /> }
          <CheckOutPanel handleLoginOpen={handleLoginOpen} />
          <Typography variant="h3">Want a DVD?</Typography>
          <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Link href={ promocode === "jbs" ? "https://intothelight.store/collections/jbs" : SHOP_URL } target='_blank'>
              <CardMedia component="img"  image="https://itl-assets.b-cdn.net/store_items/itf_dvd_doublefeature.webp" alt="dvd" sx={{ maxWidth: "380px" }} />
            </Link>
            <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1.2rem", textAlign: "center"}} >Purchase Into The Light for $14.99 or our DUAL PACK for $24.99!</Typography>
            <Button variant="contained" href={ promocode === "jbs" ? "https://intothelight.store/collections/jbs" : SHOP_URL } target='_blank'  sx={{ alignSelf: "center", mt: 1 }}>Click Here!</Button>
          </Paper>
          <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80 }}>
             <Endorsements /> 
          </Paper>
          <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80 }}>
            <MissionStatement />
          </Paper>
        </Stack>
      </Container>
    </Box>
    </>
  );
}

const CheckOutPanel = ({ handleLoginOpen }) => {
  const { is_verified } = useSelector((state) => state.user);
  
  const [tokenPayClass, setTokenPayClass] = useState(null);
  const [paymentToken, setPaymentToken] = useState();
  const reduxPromoCode = useSelector((state) => state.shop.promo_code);
  const reduxAlignment = useSelector((state) => state.shop.alignment);
  const [alignment, setAlignment] = useState(reduxAlignment ? reduxAlignment : BUY);
  const [affiliateCode, setAffiliateCode] = useState(reduxPromoCode);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [feedBack, setFeedback] = useState({passed: null, comp: null});
  const [tries, setTries] = useState(0);
  const [totalPrice, setTotalPrice] = useState(null);
  const [PIFPayload, setPIFPayload] = useState(null);
  // const [token, setToken] = useState();
  const dispatch = useDispatch();
  const [registerPayload, setRegisterPayload] = useState();
  const navigate = useNavigate();
  const user_subs = useSelector((state) => state.user.subscriptions);

  // useEffect(() => {
  //   if (affiliateCode !== reduxPromoCode) {
  //     setAffiliateCode(reduxPromoCode);
  //   }
  // },[reduxPromoCode]);
  
  useEffect(() => {
    if (alignment !== reduxPromoCode) {
      // setAffiliateCode(reduxPromoCode);
      dispatch(setReduxAlignment(alignment));
    }
  },[alignment]);
  
  useEffect(() => {
    if (affiliateCode !== reduxPromoCode) {
      // setAffiliateCode(reduxPromoCode);
      dispatch(setPromoCode(affiliateCode));
    }
  },[affiliateCode]);


  useEffect(() => {
    if (is_verified && totalPrice > 0) {
      setDisableSubmit(false);
    }
  },[is_verified, totalPrice]);


  let payload;

  if(alignment == BUY) {
    payload = {
      content_id: 1,
      payment_token: paymentToken,
      order_type: CONTENT,
      purchase_type: alignment,
      promo_code: affiliateCode ? affiliateCode : null,
      register_user: registerPayload ? registerPayload : null
    }
  } else if ( alignment == CODES) {
    payload = { ...PIFPayload, payment_token: paymentToken, register_user: registerPayload ? registerPayload : null, promo_code: affiliateCode ? affiliateCode : null, };
  }
  
  const submitPayment = async () => {
    try {
      setFeedback({passed: null, comp: null});
      setDisableSubmit(true);
      
      if (alignment == BUY) {
        const token = await dispatch(purchaseContent(payload)).unwrap();
        dispatch(setToken(token));

      } else if (alignment == CODES) {
        const resp = await dispatch(orderGiftCodes(payload)).unwrap();
        if (resp.jwt_token !== null) {
          dispatch(setToken(resp.jwt_token))
        }
        // console.log('code order resp', resp)
        // setTimeout(() => {
        //   navigate("/user/orders");
        // }, 2500);
      }
      setDisableSubmit(false);
      setFeedback({ passed: true, comp: <FeedBack passed={true} message="Purchase Completed, Thank You!" mt={1} /> });    
      setAlignment(alignment);
    } catch (exception) {
      const { detail } = exception;
      if ((detail !== null || detail !== undefined) && detail.detail){
        const detail_2 = detail.detail;
        const { description } = detail_2;
        if (description) {
          setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed. " + description} mt={1} />});
        } else {
          setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed, please try again."} mt={1} />});
        }
      } else {
        if (detail == "email already registered") {
          let msg = "Email already registered, please sign in";
          setFeedback({ passed: false, comp: <FeedBack passed={false} message={msg} mt={1} /> });
        } else {
          setFeedback({passed: false, comp: <FeedBack passed={false} message={"Checkout failed, please try again."} mt={1} />});
        }
      }
      setDisableSubmit(false);
      console.log('subpaymetn ex', exception)
    }
  }

  useEffect(() =>{
    if (paymentToken) {
      console.log('payment token', paymentToken)
      submitPayment();
    }
  }, [paymentToken]);

  const createTkn = (result) => {
    console.log('TOKEN RESULT', result);
    if (!result.token) {
      setTries(tries+1)
    }else {
      setPaymentToken(result.token);
      setTries(0);
    }
    
  }
  const createTokenErr = (result) => {
    setFeedback({passed: false, comp: <FeedBack passed={false} message={"Something went wrong, please refresh your page!"} mt={1} />});
    setDisableSubmit(false);
    console.log("error: " + result);
  }
  

  useEffect(() =>{
    if(!paymentToken && tries < 5 && tries !== 0) {
      console.log('tries', tries)
      getTokenAction();
    }
  }, [tries]);

  const getTokenAction = async () => {
    try {
      if (tokenPayClass) {
        setDisableSubmit(true);
        tokenPayClass.createToken(createTkn, createTokenErr);
      }
    } catch (exception) {
      setFeedback({passed: false, comp: <FeedBack passed={false} message={"Something went wrong, please refresh your page!"} mt={1} />});
      window.location.reload();
      setDisableSubmit(false);
      console.log('ex',exception)
    }
  }


  return(
    <Paper sx={{ py: "25px", px: 2, bgcolor: alphaBackgroundColor80 }}>
      <Box mb={2} display='flex' flexDirection='row' alignItems='center' justifyContent="space-between"><Typography variant="h3">Ways To Watch</Typography><Button variant="contained" sx={{ mt: 1, width: 200 }} onClick={() => navigate("/redeem")}>Have a gift code? Redeem</Button></Box>
      <SelectPurchaseOption alignment={alignment} setAlignment={setAlignment} />
      {is_verified && alignment === CODES && <SeeOrderHistory />}
      {alignment === BUY && <BuyForYourself setTotalPrice={setTotalPrice} />}
      {alignment === CODES && <BuyContentCodes setTotalPrice={setTotalPrice} totalPrice={totalPrice} setSubmitPayload={setPIFPayload} />}
      
      {user_subs.includes(1) && alignment === BUY ? <ContentAlreadyOwned/> : 
        <>
          <EnterPaymentInformation 
            setTokenPayClass={setTokenPayClass}
            tokenPayClass={tokenPayClass}
            hiddenPrice={totalPrice}
            showLoginModal={handleLoginOpen}
            submitAction={getTokenAction}
            setRegisterPayload={setRegisterPayload}
            setBtnDisabled={setDisableSubmit}
            isBtnDisabled={disableSubmit}
            affiliateCode={affiliateCode}
            setAffiliateCode={setAffiliateCode}
          />
        </>
      }

      {feedBack.passed !== null && feedBack.comp}
      
    </Paper>
  );
}

const endorsement_texts = [
  ['This film has been banned, doxxed, cyber attacked for all the wrong reasons. It’s time to sit down and watch it and gather friends and family around to do the same.', 'We need to maintain momentum to see, understand, and exploit the vulnerabilities and the enemy we are all facing. This film lays it all out. Thank you Director Mike Smith.', '— Lt. General Michael Flynn'],
  ['"Into the Light is one of the most powerful documentaries you will ever see. And you will have, as I did, that aha moment where you understand how you\'re being manipulated. This movie needs not only your viewership, but also your support."', '— Roger Stone'],
  ['“This film employs profoundly Ephesians 5:11: ‘And have no fellowship with the unfruitful works of darkness, but rather expose them.’ Every pastor and church in America needs to see this film!”', '— Rob McCoy, Pastor of Godspeak Calvary Chapel & Former Mayor of the City of Thousand Oaks'],
]

const Endorsements = () => {
  return(
    <Box mb={2}>
      <Box mb={1} display='flex' flexDirection='row' alignItems='center'><Typography variant="h3" mb={3}>What Others Are Saying</Typography></Box>
      <Carousel  autoPlay infiniteLoop interval={3000} showThumbs={false} showStatus={false} animationHandler="fade" showIndicators={true} showArrows={false}>
        {endorsement_texts.map(item => {
          return (
            <Fade in timeout={600}>
            <Stack spacing={1} mb={1} height={325}>
              {item.map(item => {
                return (<Typography paragraph variant="body1" textAlign="left">{item}</Typography>)
              })}
            </Stack>
            </Fade>
          )
        })}
      </Carousel>
      <Box width="100%" alignSelf='center' mb={3}>
        <video src={ITF_TRAILER} controls controlsList="nodownload"/>
        {/* <CardMedia component="img" image={itl_thumbnail}/> */}
      </Box>
    </Box>
  );
}

const MissionStatement = () => {
  return(
    <Stack spacing={2} my={2}>
      <Box mb={2} display='flex' flexDirection='row' alignItems='center'><Typography variant="h3">Our Mission</Typography></Box>
      <Typography paragraph variant="body1" maxWidth="sm" sx={{ mb: 4 }}>
        From the creators of Out of Shadows, we bring you Into the Light. Into the Light is a movie made to bring to surface that psychological operations are present and active in today's society. Into the Light will feature experts in psychological operations and mainstream media manipulation such as, General Michael Flynn, Lara Logan, Brian Gamble, Dr. Keith Rose, Boone Cutler and Mike Smith. This project brings an unbiased viewpoint to the control being done through psychological operations.
      </Typography>
      <Typography variant="body1" paragraph maxWidth="sm" sx={{ mb: 4 }}>
        We hope that this movie brings to light the problems that need to be addressed. Our goal is to activate a community of people who are not afraid to stand up for the truth and make a change. Enjoy!
      </Typography>
    </Stack>
  )
}

const SelectPurchaseOption = ({ alignment, setAlignment }) => {
  const handleChange = (e, newAlignment) => {
    if (newAlignment !== alignment && newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Grid container>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        fullWidth
        sx={{ height: '5rem' }}
      >
        <ToggleButton value={BUY} color="success" >   
          <Stack spacing={2}>
            <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem", textShadow: "#FC0 1px 0 10px" }} >Purchase For Yourself!</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton value={CODES} color="success" >
          <Stack spacing={2}>
            <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem", textShadow: "#FC0 1px 0 10px" }} >Support Our Mission!</Typography>
          </Stack>
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
}

const SeeOrderHistory = () => {
  const navigate = useNavigate();
  return(
    <Stack spacing={1} alignItems="center" mt={4}>
      <Typography variant="h6" textAlign="center" sx={{p: "5px"}}>Click below to your purchased codes and order history!</Typography>
      <Typography variant="h6" textAlign="center" sx={{p: "5px"}}>Thank you for your support!</Typography>
      <Button variant="contained" sx={{width: "350px"}} onClick={() => navigate("/user/orders")}>My Orders</Button>
    </Stack>
  )
}
const ContentAlreadyOwned = () => {
  const navigate = useNavigate();
  return(
    <Stack spacing={1} alignItems="center">
      <Typography variant="h6" textAlign="center" sx={{p: "5px"}}>This content is already owned! Please consider paying it forward!</Typography>
      <Typography variant="h6" textAlign="center" sx={{p: "5px"}}>Thank you for your support!</Typography>
      <Button variant="contained" sx={{width: "350px"}} onClick={() => navigate("/watch/1")}>Click to Watch!</Button>
    </Stack>
  )
}

const BuyForYourself = ({ setTotalPrice  }) => {
  useEffect(() =>{
    setTotalPrice(PRICE_LIST[BUY] * 100);
  }, []);

  return (
    <Stack spacing={3} alignItems='flex-start' p={1} width={1}>
      <Box alignSelf="center">
        <Typography variant="h5" mb={2}>Into The Light Now Available!</Typography>
        <Box maxWidth="600px" width={1}>
          <CardMedia component="img" image={itl_thumbnail}/>
        </Box>
      </Box>
    </Stack>
  );
}

const PayItForward_Box = ({ selectedPriceIdx, setSelectedPriceIdx, setCustomPrice }) => {
  const support_message = "Join us in spreading our message coast-to-coast";
  const setPrice = (val) => {
    if(val > 0) {
      // console.log('setting custom price')
      setCustomPrice(parseInt(val) * 100);
    }
  }
  return(
    <Stack spacing={4}>
      <Box>
        <Typography variant="p" fontFamily='Oswald Light' textAlign='center'>Thank you for joining this fight and supporting our mission! Your contribution will directly help cover essential server costs, enabling us to maintain and expand our digital infrastructure. Additionally, your support will fund future projects that continue to inspire and educate our community, while also allowing us to organize and host in-person events that foster connection and shared purpose. Together, we are building something impactful, and we couldn't do it without you.</Typography>
      </Box>
      <Box display='flex' flexDirection='row' flexWrap='wrap' gap={2} justifyContent='center'>
        {payForwardOptions_1000.map((i,idx) => {
          return (<Button key={idx} variant="contained" sx={{bgcolor: selectedPriceIdx === idx ? 'skyblue' : null}} onClick={() => { setSelectedPriceIdx(idx);}}>{i.price_label}</Button>)
        })}
      </Box>
      {selectedPriceIdx === 8 && 
      <Box alignSelf='center'>
        <TextField label='Custom Amount $:' variant="filled" sx={{maxWidth: 360}} onChange={e => setPrice(e.target.value)}/>
      </Box>}
      {/* <Box alignSelf='center'>
        <Typography variant="h5">{`Unlock ${payForwardOptions_1000[selectedPriceIdx].view_label} views`}</Typography>
      </Box> */}
    </Stack>
  );
}

const BuyContentCodes = ({ setTotalPrice, totalPrice, setSubmitPayload }) => {
  const [selectedPriceIdx, setSelectedPriceIdx] = useState(0);
  const [alignment, setAlignment] = useState(BUY);
  const [payForward, setPayForward] = useState(false);
  const [processFee, setProcessFee] = useState(null);
  const [qty, setQuantity] = useState(1);
  const [expanded, setExpanded] = useState(PAY_FORWARD);

  const handleAccordianChange = (panel) => (newExpanded) => {
    if (expanded !== panel) {
      setExpanded(newExpanded ? panel : false);
    }
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== alignment && newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  

  const updateQuantity = (val) => {
    // if (parseInt(val) && val >= min && val <= max) {
    if(val === "" || (val > 0)) {
      setQuantity(val);
    }
  }

  useEffect(() => {
    if (expanded === PAY_FORWARD) {
      setPayForward(true);
    } else {
      setPayForward(false);
    }
    const submitPayload = {
      content_id: 1,
      quantity: payForward ? payForwardOptions_1000[selectedPriceIdx].view_raw : parseInt(qty),
      custom_price: selectedPriceIdx === 8 ? totalPrice : null,
      order_type: GIFT_CODE,
      purchase_type: alignment,
      add_to_pool: payForward
    }
    setSubmitPayload(submitPayload);
  }, [expanded, payForward, qty, selectedPriceIdx, alignment, totalPrice]);




  // TODO: change this for payforward vs code
  useEffect(() =>{
    if (payForward) {
      if (selectedPriceIdx === 8) {
        setTotalPrice(totalPrice)
      } else {
        setTotalPrice(payForwardOptions_1000[selectedPriceIdx].price_raw) 
      }
      // console.log('total price: ', payForwardOptions_1000[selectedPriceIdx].price_raw)
      // setTotalPrice(payForwardOptions_1000[selectedPriceIdx].price_raw)
    } else {
      if(qty && qty > 0) {
        // setDisableSubmit(false);
      } else {
        // setDisableSubmit(true);
      }
      setTotalPrice(parseInt((PRICE_LIST[alignment] * qty) * 100));
    }
    

  }, [alignment, qty, payForward, selectedPriceIdx, totalPrice]);

  // TODO: processing fee
  useEffect(() =>{
    // setProcessFee(totalPrice / 100)
    const fee = parseInt((totalPrice / 1000) * 100);
    // const fee = (totalPrice / 10).toFixed(0);
    setProcessFee(fee);
  }, [totalPrice]);

  return (
    <Stack direction='column' spacing={3} alignItems='center' justifyContent='space-between' p={1}>
      {/* <Typography variant="h5">Supporting Our Mission!</Typography> */}
      <Stack>
        <Accordion expanded={expanded === PAY_FORWARD} onChange={handleAccordianChange(PAY_FORWARD)}>
          <AccordionSummary><Typography variant="h5" mr={2} textAlign="center">Support Our Mission</Typography><ArrowDownwardIcon fontSize="large" /></AccordionSummary>
          <AccordionDetails>
            <PayItForward_Box selectedPriceIdx={selectedPriceIdx} setSelectedPriceIdx={setSelectedPriceIdx} setCustomPrice={setTotalPrice} />
          </AccordionDetails>
        </Accordion>
        {/* <Box alignSelf="center" my={2}><Typography variant="h5" textAlign="center">OR</Typography></Box>
        <Accordion expanded={expanded === CODES} onChange={handleAccordianChange(CODES)}>
          <AccordionSummary><Typography variant="h5" mr={2} textAlign="center">Click To Purchase Film Codes</Typography><ArrowDownwardIcon fontSize="large" /></AccordionSummary>
          <AccordionDetails sx={{ alignSelf: "center" }} >
            <Box width={1} alignSelf="center" sx={{ alignSelf: "center" }}>
              <Box mb={2}>
                <Typography variant="p" fontFamily='Oswald Light' textAlign='center'>Purchase redeemable codes you can share with anyone and allow them to unlock the film Into The Light!</Typography>
              </Box>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                fullWidth
              > */}
                {/* <ToggleButton value={RENT} color="success" >
                  <Stack spacing={2}>
                    <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >Rent ${PRICE_LIST[RENT]}</Typography>
                    <Typography variant="subtitle1" sx={{ fontFamily: "Lora", fontSize: ".75rem", }} >Rent for 3 Days!</Typography>
                  </Stack>
                </ToggleButton> */}
                {/* <ToggleButton value={BUY} color="success" >
                  <Stack spacing={2}>
                    <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem"}} >Buy ${PRICE_LIST[BUY]}</Typography>
                    <Typography variant="subtitle1" sx={{ fontFamily: "Lora", fontSize: ".75rem", }} >Watch Forever!</Typography>
                  </Stack>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between' my={2} px={1} width={1} >
              <Box display='flex' flexDirection='row' alignItems='center' justifyContent="space-evenly">
                <TextField label='Quantity' variant="standard" value={qty} sx={{width: 120}} onChange={e => updateQuantity(e.target.value)}/>
                <Typography variant="body1" sx={{ fontFamily: "Lora", fontSize: "1rem", mt: 2}} >x ${PRICE_LIST[alignment]}</Typography>
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion> */}
      </Stack>
    </Stack>
  );
}

let payForwardOptions_1000 = [
  {
    price_label: '$10',
    price_raw: 1000,
    view_label: '5',
    view_raw: 5,

  },
  {
    price_label: '$20',
    price_raw: 2000,
    view_label: '12',
    view_raw: 12,
  },
  {
    price_label: '$50',
    price_raw: 5000,
    view_label: '30',
    view_raw: 30,
  },
  {
    price_label: '$100',
    price_raw: 10000,
    view_label: '60',
    view_raw: 60,
  },
  {
    price_label: '$250',
    price_raw: 25000,
    view_label: '150',
    view_raw: 150,
  },
  {
    price_label: '$500',
    price_raw: 50000,
    view_label: '300',
    view_raw: 300,
  },
  {
    price_label: '$750',
    price_raw: 75000,
    view_label: '500',
    view_raw: 500,
  },
  {
    price_label: '$1,000',
    price_raw: 100000,
    view_label: '700',
    view_raw: 700,
  },
  {
    price_label: 'Custom Amount',
    price_raw: 0,
    view_label: 'custom',
    view_raw: 0,
  }
];


const light = {
  palette: {
    mode: "light",
  },
};

const EnterPaymentInformation = ({ setTokenPayClass, tokenPayClass, hiddenPrice,showLoginModal, submitAction, setRegisterPayload, setBtnDisabled, isBtnDisabled, affiliateCode, setAffiliateCode }) => {
  const { is_verified } = useSelector((state) => state.user);
  // const reduxPromoCode = useSelector((state) => state.shop.promo_code);
  // const [affiliateCode, setAffiliateCode] = useState(reduxPromoCode);

  // useEffect(() => {
  //   if (affiliateCode !== reduxPromoCode) {
  //     setAffiliateCode(reduxPromoCode);
  //   }
  // },[reduxPromoCode]);

  return(
    <Stack spacing={2}  px={1} alignItems="" alignSelf={is_verified ? 'center' : null }>
      <Typography variant="body1" mt={.5} color="lightblue" fontSize="1.5rem"><b>Total:</b> ${(hiddenPrice / 100).toFixed(2)}</Typography>
      <Divider flexItem />
      <PaymentScript setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/>
        <Stack direction={{ xs: "column-reverse", md: "row" }} spacing={6} alignSelf="center" >
          {!is_verified && 
            <>
            <Stack spacing={1}>
              <Box>
                <Typography variant="h6" gutterBottom mb={2}>
                  Checkout Information
                </Typography>
                <SignUpTab setRegisterPayload={setRegisterPayload} setDisable={setBtnDisabled} showLoginModal={showLoginModal} hiddenPrice={hiddenPrice} />
              </Box>
            </Stack>
          </>
          }
          <Box>
            <Stack spacing={3}>
              <Box width='360px'>
                <PaymentScript setTokenPayClass={setTokenPayClass} tokenPayClass={tokenPayClass}/>
                <ThemeProvider theme={createTheme(light)}>
                  <Typography variant="h6" gutterBottom>
                    Payment method
                  </Typography>
                  <Paper>
                    <Box width={1}>
                      <Grid container spacing={0} component="form" id='paymentForm'>
                        <Grid item xs={12} md={12} id="card" />
                        <Grid item xs={12} md={12} id="amount" visibility='hidden' position='absolute'>{hiddenPrice}</Grid>
                        {/* <Grid item xs={12} md={12} ><span id="errorMessage"></span></Grid> */}
                        <Typography variant="span" id="errorMessage" sx={{display: 'none'}}/>
                      </Grid>
                    </Box>
                  </Paper>
                </ThemeProvider>
              </Box>
              <TextField label='Affiliate Code?' variant="filled" size="small" value={affiliateCode} sx={{width: 360}} onChange={e => setAffiliateCode(e.target.value)}/>
            </Stack>
          </Box>
        </Stack>
        {is_verified ?<Button variant="contained" size="large" disabled={isBtnDisabled} sx={{width: "350px", alignSelf: "center"}} onClick={() => submitAction()}>Purchase!</Button>  : <Button variant="contained" size="large" disabled={isBtnDisabled} onClick={() => submitAction()}>Register and Purchase!</Button>}
    </Stack>
  );
}

const SignUpTab = ({ setRegisterPayload, setDisable, showLoginModal, hiddenPrice }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isPwVisible, setIsPwVisible] = useState(false);
  
  useEffect(() => {
    if (!isNullOrEmpty(firstName) && !isNullOrEmpty(lastName) && !isNullOrEmpty(email) && isEmail(email) && checkPassword(password) && hiddenPrice > 0 ) {
      const payload = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        type: REGISTER,
        remember_me: true
      };
      setRegisterPayload(payload);
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [firstName, lastName, email, password, hiddenPrice]);

  return (
    <>
      <Stack spacing={4} component="form" mt={1}>
        <Stack direction="row" spacing={1}>
          <TextField required placeholder="First name" variant="filled" fullWidth size="small" onChange={(e) => setFirstName(e.target.value)} autoComplete="given-name"/>
          <TextField required placeholder="Last name" variant="filled" fullWidth size="small" onChange={(e) => setLastName(e.target.value)} autoComplete="family-name"/>
        </Stack>
        <TextField required fullWidth autoComplete="email" placeholder="e-mail" size="small" variant="filled" sx={{ mb: "1rem" }} onChange={(e) => setEmail(e.target.value)} />
        <Box>
          <Tooltip disableHoverListener open={isPwVisible} placement="top" title={<PasswordRequirements value={password} />} >
            <Box>
              <PasswordField required variant="filled" placeholder="password" autoComplete="new-password" size="small" onChange={(e) => setPassword(e.target.value)} onFocus={()=>setIsPwVisible(true)} onBlur={() => setIsPwVisible(false)}/>
            </Box>
          </Tooltip>
          <Typography variant="subtitle2" color="lightblue" onClick={()=> showLoginModal()} sx={{'&:hover':{cursor: 'pointer'} }}>Have an account? Sign In</Typography>
        </Box>
      </Stack>
    </>
  );
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
const PaymentScript = ({ setTokenPayClass, tokenPayClass }) => {
  useEffect(() => {
    if (!tokenPayClass) {
      const script = document.createElement('script');
      // const script = document.createElement("script");
      // // DEV
      // const url = 'https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js';
      
      // // PROD
      const url = 'https://api.tpcgateway.com/WebSecurity/TokenPay/js/tokenPay.js';
      script.src = url
      script.async = true;
      script.onload = () => scriptLoaded();

      document.body.appendChild(script);
      return () => {
        // scriptLoaded();
        document.body.removeChild(script);
      }
    }
    
    
  }, [tokenPayClass]);

  function scriptLoaded() {
    
    if (window['TokenPay']) {
      try {
        // DEV
        // const tokenpay = window.TokenPay('tokenpay56036api20231301011300303');
        // PROD Batch
        // const tokenpay = window.TokenPay('tokenpay1783api20234804074845160');

        // PROD no-batch
        const tokenpay = window.TokenPay('tokenpay1803api20230721040747186');
        tokenpay.initialize({
          dataElement: '#card',
          errorElement: '#errorMessage',
          amountElement: '#amount',
          //if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
          //if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
          //if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
          useStyles: false,
          useACH: false,
          disableZip: false,
          disableCvv: false
        });
        setTokenPayClass(tokenpay);

      } catch (exception) {
        console.log('TP error', exception)
      }


    }
  }



}
